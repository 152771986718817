import { Projects } from '@pages/projects/Projects';
import { ProjectsDetails } from '@pages/projects/ProjectsDetails';
import { ProjectsEdit } from '@pages/projects/ProjectsEdit';

export const projectsICRepresentativeRoutes = [
    {
        path: '/interconnection-representative/projects',
        Component: Projects,
    },
    {
        path: '/interconnection-representative/projects/:id',
        Component: ProjectsDetails,
    },
    {
        path: '/interconnection-representative/projects/:id/edit',
        Component: ProjectsEdit,
    },
];
