import { Tasks } from '@pages/tasks/Tasks';
import { TasksCreate } from '@pages/tasks/TasksCreate';
import { TasksDetails } from '@pages/tasks/TasksDetails';
import { TasksEdit } from '@pages/tasks/TasksEdit';

export const tasksICRepresentativeRoutes = [
    {
        path: '/interconnection-representative/tasks',
        Component: Tasks,
    },
    {
        path: '/interconnection-representative/tasks/:id',
        Component: TasksDetails,
    },
    {
        path: '/interconnection-representative/tasks/create',
        Component: TasksCreate,
    },
    {
        path: '/interconnection-representative/tasks/:id/edit',
        Component: TasksEdit,
    },
];
