import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
    AdminBaseURL,
    DefaultBaseURL,
    getMutationUrlParams,
} from './urlHelpers';

export enum UserGroups {
    ADMIN = 'admin',
    DESIGNER = 'designer',
    MANAGER = 'manager',
    EMPLOYEE = 'employee',
    EE_ENGINEER = 'ee_engineer',
    PE_ENGINEER = 'pe_engineer',
    INTERCONNECTION_REPRESENTATIVE = 'interconnection_representative',
}

export enum UserStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface INewUser {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    group?: UserGroups;
    supervisor?: string;
    address?: string;
    companyId?: string;
    taskCreated?: string;
    taskAssigned?: string;
    taskClosed?: string;
}

export interface IEditUser extends INewUser {
    status?: UserStatus;
}

export const useCreateUser = () => {
    const client = useQueryClient();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? AdminBaseURL : DefaultBaseURL;

    return useMutation({
        mutationFn: async (newUser: INewUser) => {
            const {
                data: { user },
            } = await axios.post(url + '/users', newUser, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return {
                user,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['users'] });
        },
    });
};

export const useEditUser = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (userNewData: IEditUser) => {
            const {
                data: { user },
            } = await axios.patch(
                DefaultBaseURL + '/users/' + id,
                userNewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                user,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['users'] });
        },
    });
};

export const useDeleteUser = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (userId: string) => {
            await axios.delete(AdminBaseURL + `/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['users'] });
        },
    });
};

export const useGetUsers = (
    filter?: Partial<{ [x: string]: any }>,
    forceFilter?: boolean
) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? AdminBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/users`,
        forceFilter ? filter : filter ?? urlState,
        'users'
    ).href;

    return useQuery({
        queryKey: ['users', href, group],
        queryFn: () => fetchGBI(href),
    });
};

export const useGetUser = (userId: string) =>
    useQuery({
        queryKey: ['users', userId],
        queryFn: () => fetchGBI(DefaultBaseURL + `/users/${userId}`),
    });
