import useUrlState from '@ahooksjs/use-url-state';
import fetchGBI from '@queries/fetchGBI';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
    AdminBaseURL,
    DefaultBaseURL,
    getMutationUrlParams,
} from './urlHelpers';

export enum TaskStatuses {
    IN_PROGRESS = 'in_progress',
    OPEN = 'open',
    BACKLOG = 'backlog',
    APPROVED = 'approved',
    COMPLETED = 'completed',
    REJECTED = 'rejected',
    CLOSED = 'closed',
    BLOCKED = 'blocked',
}

export enum TaskTypes {
    DESIGN_PLANS = 'design_plans',
    EE_STAMP = 'ee_stamp',
    PE_STAMP = 'pe_stamp',
    CUSTOMER_REVIEW = 'customer_review',
    WET_STAMP = 'wet_stamp',
    OTHER = 'other',
    INTERCONNECTION = 'interconnection',
}

export enum TaskPriority {
    HIGH = '10_high',
    MEDIUM = '20_medium',
    LOW = '30_low',
}

export interface INewTask {
    title?: string;
    description?: string;
    dueDate?: string;
    assigneeId?: string;
    projectId?: string;
    type?: TaskTypes;
    priority?: TaskPriority;
    blockedBy?: { id: string };
}

export interface Task extends INewTask {
    id: string;
    resolution?: string;
    status: string;
    assignee?: string;
}

export interface IEditTask extends INewTask {
    status: TaskStatuses;
    blockedBy?: { id: string };
}

export interface ITasksCreateResponse {
    id: string;
    title: string;
    type: string;
    priority: string;
    description: string;
    dueDate: string;
    assignee: {
        id: string;
    };
    project: {
        id: string;
    };
    resolution: string | null;
    closedAt: string | null;
    status: string;
    createdAt: string;
}

export const useCreateTask = () => {
    const client = useQueryClient();

    return useMutation<ITasksCreateResponse, unknown, INewTask>({
        mutationFn: async (
            newTask: INewTask
        ): Promise<ITasksCreateResponse> => {
            const { data } = await axios.post<ITasksCreateResponse>(
                DefaultBaseURL + '/tasks',
                newTask,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return data;
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['tasks'] });
        },
    });
};

export const useEditTask = (id: string) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (taskNewData: IEditTask) => {
            const {
                data: { task },
            } = await axios.patch(
                DefaultBaseURL + '/tasks/' + id,
                taskNewData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
            return {
                task,
            };
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['tasks', id] });
        },
    });
};

export const useDeleteTask = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (taskId: string) => {
            await axios.delete(DefaultBaseURL + `/tasks/${taskId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['tasks'] });
        },
    });
};

export const useGetTasks = (searchParams?: Partial<{ [x: string]: any }>) => {
    const [urlState] = useUrlState();
    const group = localStorage.getItem('userGroup');
    const url = group === 'admin' ? AdminBaseURL : DefaultBaseURL;
    const href = getMutationUrlParams(
        `${url}/tasks`,
        searchParams ?? urlState,
        'tasks'
    ).href;

    return useQuery({
        queryKey: ['tasks', href, group],
        queryFn: () => fetchGBI(href),
    });
};
export const useGetTasksByProject = (projectId: string, status?: string) => {
    return useQuery({
        enabled: !!projectId,
        queryKey: ['tasks', projectId],
        queryFn: () =>
            fetchGBI(
                DefaultBaseURL +
                    `/tasks/project/${projectId}${
                        status ? `?filter.status=${status}` : ''
                    }`
            ),
    });
};

export const useGetTask = (taskId: string) =>
    useQuery({
        queryKey: ['tasks', taskId],
        queryFn: () => fetchGBI(DefaultBaseURL + `/tasks/${taskId}`),
    });

export const useCloseTask = () => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (data: { taskId: string; status: string }) => {
            await axios.patch(
                DefaultBaseURL + `/tasks/close/${data.taskId}`,
                {
                    taskTypeResolution: data.status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                }
            );
        },
        onSuccess: () => {
            return client.invalidateQueries({ queryKey: ['tasks'] });
        },
    });
};
