import useUrlState from '@ahooksjs/use-url-state';
import { ReactComponent as EmptyTasksIcon } from '@assets/emptyTasksIcon.svg';
import { ReactComponent as TasksIcon } from '@assets/tasksIcon.svg';
import { DetailTitle } from '@components/DetailTitle/DetailTitle';
import { EmptyPage } from '@components/EmptyPage/EmptyPage';
import { Loader } from '@components/Loader/Loader';
import { Pagination } from '@components/Pagination/Pagination';
import { useDeleteTask, useGetTasks } from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import { isObjectEmpty } from '@utils/utils';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import './Tasks.scss';
import { TasksTable } from './TasksTable';
import { tasksTableSetup } from './TasksTableConfig';

export const Tasks = () => {
    const [urlState, setUrlState] = useUrlState();
    const isFiltered = !isObjectEmpty(urlState);
    const group = getGroup();

    const navigate = useNavigate();
    const { data: tasksData, fetchStatus } = useGetTasks();
    const deleteMutation = useDeleteTask();

    useEffect(() => {
        if (
            group === 'admin' ||
            group === 'designer' ||
            group === 'interconnection-representative' ||
            group === 'pe-engineer' ||
            group === 'ee-engineer'
        ) {
            if (Object.keys(urlState).length === 0) {
                setUrlState({ 'filter.status': '$not:closed' });
            }
        }
    }, [group]);

    const handleEdit = (data: any) => {
        return navigate(`/${getGroup()}/tasks/${data.row.getValue('id')}/edit`);
    };

    const handleDelete = (id: string) => {
        deleteMutation.mutate(id);
    };

    const getTasksComponent = () => {
        if (fetchStatus === 'fetching') {
            return <Loader />;
        }

        if (
            fetchStatus === 'idle' &&
            (tasksData?.data?.length > 0 || isFiltered)
        ) {
            return (
                <TasksTable
                    propData={tasksData.data}
                    tableSetup={tasksTableSetup(
                        handleDelete,
                        handleEdit,
                        urlState,
                        setUrlState
                    )}
                />
            );
        }

        if (
            fetchStatus === 'idle' &&
            tasksData?.data?.length === 0 &&
            !isFiltered
        ) {
            return (
                <EmptyPage
                    title="Looks like noone has added a task yet"
                    to={`/${getGroup()}/tasks/create`}
                    buttonName="Create new task"
                    icon={<EmptyTasksIcon />}
                />
            );
        }

        return null;
    };

    return (
        <div className="tasks-page content-wrapper">
            <DetailTitle title="Tasks" icon={<TasksIcon />} />

            {getTasksComponent()}

            <Pagination
                currentPage={tasksData?.meta?.currentPage}
                totalPages={tasksData?.meta?.totalPages}
                prefix="tasks"
            />
        </div>
    );
};
