import { FormGenerator } from '@components/Forms/FormGenerator';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { UserContext } from '@pages/RootWithSidebar';
import { useGetAllCompanies } from '@queries/companies.query';
import { INewUser, UserGroups, useCreateUser } from '@queries/users.query';
import { getGroup } from '@utils/getGroup';
import { getPostMutationObject } from '@utils/getPostMutationObject';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import './Users.scss';
import { usersFormConfig } from './UsersFormConfig';
import { UsersNavigation } from './header/UsersNavigation';

export const userTypes = [
    {
        id: UserGroups.ADMIN,
        valueToShow: 'Admin',
    },
    {
        id: UserGroups.DESIGNER,
        valueToShow: 'Designer',
    },
    {
        id: UserGroups.EE_ENGINEER,
        valueToShow: 'EE Engineer',
    },
    {
        id: UserGroups.PE_ENGINEER,
        valueToShow: 'PE Engineer',
    },
    {
        id: UserGroups.MANAGER,
        valueToShow: 'Manager',
    },
    {
        id: UserGroups.EMPLOYEE,
        valueToShow: 'Employee',
    },
    {
        id: UserGroups.INTERCONNECTION_REPRESENTATIVE,
        valueToShow: 'Interconnection Representative',
    },
];

export const UsersCreate = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const userMutation = useCreateUser();
    let availableRoles = userTypes;

    const { data: companyData } = useGetAllCompanies();

    const [companyId, setCompanyId] = useState('');

    const setCompany = (id: string) => {
        setCompanyId(id);
    };

    if (user?.group === UserGroups.MANAGER) {
        availableRoles = userTypes.filter(
            user =>
                user.id !== UserGroups.ADMIN &&
                user.id !== UserGroups.PE_ENGINEER &&
                user.id !== UserGroups.EE_ENGINEER &&
                user.id !== UserGroups.DESIGNER
        );
    } else if (user?.group !== UserGroups.ADMIN) {
        availableRoles = userTypes.filter(
            user =>
                user.id !== UserGroups.ADMIN && user.id !== UserGroups.EMPLOYEE
        );
    } else if (user?.group === UserGroups.ADMIN) {
        availableRoles = userTypes;
    }

    const [userTypeId, setUserTypeId] = useState<UserGroups>(
        undefined as any as UserGroups
    );
    const [isLoading, setIsLoading] = useState(false);

    const userConfig = usersFormConfig(userTypeId, availableRoles, setUserTypeId);

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const usersData: INewUser = getPostMutationObject(e, userConfig);
        if (usersData) {
            usersData.group = userTypeId;
            usersData.lastName = e.target.lastName.value;
            usersData.address = 'address placeholder';
            companyId !== '' && (usersData.companyId = companyId);
        }

        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(userConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, usersData)) {
            setIsLoading(false);
        } else {
            return userMutation.mutate(usersData, {
                onSuccess: () => {
                    toast.success('User created successfully');
                    return navigate(`/${getGroup()}/users`);
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="users-create content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <NavigationHeader detailTitle="Creating new user">
                    <UsersNavigation
                        submitButtonTitle="Save new user"
                        isDisabled={isLoading}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        <FormGenerator
                            settings={usersFormConfig(
                                userTypeId,
                                availableRoles,
                                setUserTypeId,
                                companyData?.data?.map((company: any) => ({
                                    id: company.id,
                                    valueToShow: company.name,
                                })),
                                setCompany,
                                user?.group
                            )}
                            defaultData={{
                                group: { id: userTypeId },
                                companyId,
                            }}
                            formTitle="General details"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
