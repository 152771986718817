import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { RootWithSidebar } from '../pages/RootWithSidebar';
import { ForgotPassword } from '../pages/verification/ForgotPassword';
import { NewPassword } from '../pages/verification/NewPassword';
import { EmailCodeForm } from '../pages/verification/Verification';
import { ErrorPage } from '../shared/components/ErrorPage/ErrorPage';
import { Authenticate } from '../shared/components/LoginPage/Authenticate';
import { LoginPage } from '../shared/components/LoginPage/LoginPage';
import { companiesAdminRoutes } from './admin/companies';
import { jurisdictionAdminRoutes } from './admin/jurisdiction';
import { projectsAdminRoutes } from './admin/projects';
import { settingsAdminRoutes } from './admin/settings';
import { tasksAdminRoutes } from './admin/tasks';
import { usersAdminRoutes } from './admin/users';
import { jurisdictionDesignerRoutes } from './designer/jurisdiction';
import { projectsDesignerRoutes } from './designer/projects';
import { settingsDesignerRoutes } from './designer/settings';
import { tasksDesignerRoutes } from './designer/tasks';
import { projectsEEEngineerRoutes } from './eeEngineer/projects';
import { settingsEEEngineerRoutes } from './eeEngineer/settings';
import { tasksEEEngineerRoutes } from './eeEngineer/tasks';
import { projectsEmployeeRoutes } from './employee/projects';
import { settingsEmployeeRoutes } from './employee/settings';
import { tasksEmployeeRoutes } from './employee/tasks';
import { usersEmployeeRoutes } from './employee/users';
import { projectsICRepresentativeRoutes } from './interconnection/projects';
import { settingsICRepresentativeRoutes } from './interconnection/settings';
import { tasksICRepresentativeRoutes } from './interconnection/tasks';
import { jurisdictionManagerRoutes } from './manager/jurisdiction';
import { projectsManagerRoutes } from './manager/projects';
import { settingsManagerRoutes } from './manager/settings';
import { tasksManagerRoutes } from './manager/tasks';
import { usersManagerRoutes } from './manager/users';
import { projectsPEEngineerRoutes } from './peEngineer/projects';
import { settingsPEEngineerRoutes } from './peEngineer/settings';
import { tasksPEEngineerRoutes } from './peEngineer/tasks';

// todo check where are pages are stored (in 'pages/admin/[components]') and think about better place for them
// todo right now all of the views are based on the same main component
const adminRoutes = {
    path: '/admin',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...companiesAdminRoutes,
        ...projectsAdminRoutes,
        ...tasksAdminRoutes,
        ...jurisdictionAdminRoutes,
        ...usersAdminRoutes,
        ...settingsAdminRoutes,
    ],
};

const managerRoutes = {
    path: '/manager',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsManagerRoutes,
        ...tasksManagerRoutes,
        ...jurisdictionManagerRoutes,
        ...usersManagerRoutes,
        ...settingsManagerRoutes,
    ],
};

const employeeRoutes = {
    path: '/employee',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsEmployeeRoutes,
        ...tasksEmployeeRoutes,
        // TODO jurisdiction removed until client decides what to do
        // ...jurisdictionEmployeeRoutes,
        ...usersEmployeeRoutes,
        ...settingsEmployeeRoutes,
    ],
};

const designerRoutes = {
    path: '/designer',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsDesignerRoutes,
        ...tasksDesignerRoutes,
        ...jurisdictionDesignerRoutes,
        ...settingsDesignerRoutes,
    ],
};

const eeEngineerRoutes = {
    path: '/ee-engineer',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsEEEngineerRoutes,
        ...tasksEEEngineerRoutes,
        // ...jurisdictionEEEngineerRoutes,
        ...settingsEEEngineerRoutes,
    ],
};

const peEngineerRoutes = {
    path: '/pe-engineer',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsPEEngineerRoutes,
        ...tasksPEEngineerRoutes,
        // ...jurisdictionPEEngineerRoutes,
        ...settingsPEEngineerRoutes,
    ],
};

const interconnectionRepresentativeRoutes = {
    path: '/interconnection-representative',
    element: <RootWithSidebar />,
    errorElement: <ErrorPage />,
    children: [
        ...projectsICRepresentativeRoutes,
        ...tasksICRepresentativeRoutes,
        // ...jurisettingsICRepresentativeRoutes,
        ...settingsICRepresentativeRoutes,
    ],
};

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <LoginPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/authenticate',
        element: <Authenticate />,
    },
    {
        path: '/verify',
        element: <EmailCodeForm />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/new-password',
        element: <NewPassword />,
    },
    // todo this can be replaced with page not found instead of error
    {
        path: '*',
        element: <ErrorPage />,
    },
    adminRoutes,
    managerRoutes,
    employeeRoutes,
    designerRoutes,
    eeEngineerRoutes,
    peEngineerRoutes,
    interconnectionRepresentativeRoutes,
]);
