import { FormGenerator } from '@components/Forms/FormGenerator';
import { SelectedElement } from '@components/Forms/components/searchableDropdown/SearchableDropdown';
import { NavigationHeader } from '@components/NavigationHeader/NavigationHeader';
import { TasksSidebar } from '@pages/tasks/TasksSidebar';
import { TasksNavigation } from '@pages/tasks/header/TasksNavigation';
import { useAttachDeliverable } from '@queries/deliverables.query';
import { IResourceFileResponse, useCreateFile } from '@queries/files.query';
import { useGetProjects } from '@queries/projects.query';
import {
    INewTask,
    TaskPriority,
    TaskTypes,
    useCreateTask,
    useGetTasksByProject,
} from '@queries/tasks.query';
import { getGroup } from '@utils/getGroup';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getElementsToValidate, isInvalidField } from 'src/validations/helpers';

import './Tasks.scss';
import { tasksFormConfig } from './TasksFormConfig';

export const getTasksDataObject = (
    e: any,
    assigneeId: string | null
): INewTask => {
    return {
        title: e.target.title.value,
        description: e.target.description.value,
        dueDate: e.target.dueDate.value,
        projectId: '',
        type: TaskTypes.OTHER,
        priority: TaskPriority.MEDIUM,
        assigneeId: assigneeId ? assigneeId : undefined,
    };
};

export const taskTypes = [
    {
        id: TaskTypes.DESIGN_PLANS,
        valueToShow: 'Design Plans',
    },
    {
        id: TaskTypes.EE_STAMP,
        valueToShow: 'EE Stamp',
    },
    {
        id: TaskTypes.PE_STAMP,
        valueToShow: 'PE Stamp',
    },
    {
        id: TaskTypes.CUSTOMER_REVIEW,
        valueToShow: 'Customer Review',
    },
    {
        id: TaskTypes.WET_STAMP,
        valueToShow: 'Wet Stamp',
    },
    {
        id: TaskTypes.OTHER,
        valueToShow: 'Other',
    },
    {
        id: TaskTypes.INTERCONNECTION,
        valueToShow: 'Interconnection',
    },
];

export const TasksCreate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const projectIdForTaskCreation: string | undefined =
        location.state.projectIdForTaskCreation;
    const createTaskMutation = useCreateTask();
    const params = useParams();
    const attachDeliverableMutation = useAttachDeliverable();
    const [taskType, setTaskType] = useState<TaskTypes>(TaskTypes.OTHER);
    const [assignee, setAssignee] = useState<SelectedElement>({
        id: null,
        valueToShow: 'Not Assigned',
    });
    const [selectedBlockedByTaskId, setSelectedBlockedByTaskId] =
        useState<string>('');

    const { data: projectsData } = useGetProjects(
        projectIdForTaskCreation
            ? {
                  'filter.id': projectIdForTaskCreation,
              }
            : undefined
    );
    if (
        !assignee.id &&
        projectsData?.data?.length === 1 &&
        taskType === TaskTypes.CUSTOMER_REVIEW
    ) {
        setAssignee({
            id: projectsData.data[0].owner.id,
            valueToShow: `${projectsData.data[0].owner.firstName} ${projectsData.data[0].owner.lastName}`,
        });
    }
    const createFileMutation = useCreateFile();

    const projectsSelect = projectsData?.data?.map((project: any) => {
        return {
            id: project.id,
            valueToShow: project.name,
        };
    });

    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File[]>();
    const [projectId, setProjectId] = useState(
        projectIdForTaskCreation
            ? projectIdForTaskCreation
            : params?.projectId ?? ''
    );
    const { data: tasksByProject } = useGetTasksByProject(
        projectId,
        '$not:closed'
    );
    const tasksSelect = tasksByProject?.data?.map((task: any) => {
        return {
            id: task.id,
            valueToShow: task.title,
        };
    });
    const handleSelectedBlockedByTask = (selectedTaskId: string) => {
        setSelectedBlockedByTaskId(selectedTaskId);
    };

    const attachDeliverable = (
        projectId: string,
        fileId: string,
        taskType: string
    ) => {
        attachDeliverableMutation.mutate(
            {
                projectId,
                fileId,
                type: taskType,
            },
            {
                onSuccess: () => {
                    toast.update('deliverable-toast', {
                        render: 'File uploaded & attached successfully',
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    });
                },
            }
        );
    };

    const tasksConfig = tasksFormConfig({
        isCreateMode: true,
        taskTypeSelectData: taskTypes,
        projectsSelectData: projectsSelect,
        onTaskTypeChange: setTaskType,
        onProjectIdChange: setProjectId,
        onSelectedFile: setSelectedFile,
        selectedFile,
        onBlockedByTaskIdChange: handleSelectedBlockedByTask,
        blockedByTaskSelectData: tasksSelect,
        projectIdForTaskCreation,
    });

    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const tasksData: INewTask = getTasksDataObject(e, assignee.id);
        tasksData && (tasksData.type = taskType);
        tasksData && (tasksData.projectId = projectId);
        if (selectedBlockedByTaskId) {
            tasksData.blockedBy = { id: selectedBlockedByTaskId };
        }
        const { fieldsToValidate, inputsToValidate } =
            getElementsToValidate(tasksConfig);

        if (isInvalidField(fieldsToValidate, inputsToValidate, tasksData)) {
            setIsLoading(false);
        } else {
            return createTaskMutation.mutate(tasksData, {
                onSuccess: taskCreateResponse => {
                    if (selectedFile) {
                        const loadingFilesToast =
                            toast.loading('Uploading files...');

                        Array.from(selectedFile).forEach(file => {
                            return createFileMutation.mutate(
                                {
                                    body: file,
                                    resourceId: taskCreateResponse.id,
                                },
                                {
                                    onSuccess: async (
                                        fileUploadResponse: IResourceFileResponse[]
                                    ) => {
                                        attachDeliverable(
                                            projectId,
                                            fileUploadResponse[0].id,
                                            taskType
                                        );
                                        toast.update(loadingFilesToast, {
                                            render: 'File(s) uploaded successfully',
                                            type: 'success',
                                            isLoading: false,
                                            autoClose: 2500,
                                        });
                                        navigate(`/${getGroup()}/tasks`);
                                    },
                                    onError: () => {
                                        setIsLoading(false);
                                    },
                                }
                            );
                        });
                    } else {
                        setIsLoading(false);
                        toast.success('Task created successfully');
                        navigate(`/${getGroup()}/tasks`);
                    }
                },
                onError: () => {
                    setIsLoading(false);
                },
            });
        }
    };

    return (
        <div className="tasks-create content-wrapper with-header">
            <form onSubmit={e => onSubmit(e)} noValidate>
                <NavigationHeader detailTitle="Creating new task">
                    <TasksNavigation
                        submitButtonTitle="Save new task"
                        isDisabled={isLoading}
                    />
                </NavigationHeader>

                <div className="row">
                    <div className="col-lg-6">
                        <FormGenerator
                            settings={tasksConfig}
                            formTitle="General details"
                            defaultData={{
                                type: { id: taskType },
                                projectId,
                                projectName: projectsData?.data[0]?.name,
                                taskId: selectedBlockedByTaskId,
                                blockedByTaskId: selectedBlockedByTaskId,
                            }}
                        />
                    </div>
                    <TasksSidebar
                        onAssigneeSelected={selectedAssignee => {
                            setAssignee(selectedAssignee);
                        }}
                        assignee={assignee}
                        disableOnSubmit={true}
                    />
                </div>
            </form>
        </div>
    );
};
